export const browserCheckConfig = {
  // Specifies required browser versions
  required: {
    e: 79, // ie/edge version
    f: 50, // firefox version
    o: 50, // opera version
    s: 10, // safari version
    c: 56, // chrome version
    o_a: 52, // opera android
    uc: 11.4, // UC Browser
    ios: 10, // iOS browser
    samsung: 6.2, // Samsung Internet (Android)
  },
  reminder: 0, // 0 = show all the time
  insecure: false,
  //unsupported: true, // uncommend when notification about missing unsupported from the author is wished
  api: 2021.09,
  test: false, // should only be used for testing the browser message
  onshow: undefined,
  nomessage: true, // avoid that banner is shown
};
