import browserUpdate from 'browser-update';
import { browserCheckConfig } from './config';

document.readyState != 'loading'
  ? initBrowserCheck()
  : document.addEventListener('DOMContentLoaded', initBrowserCheck);

function initBrowserCheck() {
  const element = document.getElementsByTagName('wac-browser-check')[0];

  if (!!element && element.dataset.enabled === 'true') {
    const {
      redirectDomain = 'https://www.wacker.com',
      redirectPath = '/outdated-browser.html',
    } = element.dataset;
    const onshow = () => window.open(redirectDomain.concat(redirectPath), '_self')

    browserUpdate({...browserCheckConfig, onshow});
  }
}
